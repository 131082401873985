<script lang="ts" setup>
const { open } = useAppModals();
const { isUserInGame } = useGameModal();
const { data: appInitData } = useAppInitData();
const { valueProgress, currentLevel, isProgressBlocked } = useStatus();
const { refresh } = useStatusData();

const isVip = computed(() => currentLevel.value?.title === "vip");

const completedVip = computed(() => appInitData.value?.rankLeague?.playerFullCompletedLeague);

const percentValue = computed(() => (completedVip.value ? 100 : valueProgress.value));

const handleOpenProgress = () => {
	if (isUserInGame.value) {
		if (isProgressBlocked.value) {
			open("LazyOModalVipClubProfile", { inGame: true });
			return;
		}

		open("LazyOModalGameVipOverview");
		return;
	}

	nextTick(() => {
		refresh();
		navigateTo("/vip");
	});
};

const progressColor = computed(() => (percentValue.value === 100 ? "var(--secondary-400)" : "var(--neutral-800)"));
</script>

<template>
	<div class="status-btn" @click="handleOpenProgress">
		<MVipClubStatusIcon
			:rank="currentLevel?.title"
			:is-vip="isVip"
			:width="isVip ? 110 : 94"
			:height="isVip ? 74 : 78"
			:icon-size="isVip ? 22 : 12"
			class="rank"
		/>
		<AProgressCircle
			class="season-progress"
			:color="progressColor"
			colorValue="var(--secondary-400)"
			:size="74"
			:value="percentValue"
		/>
		<div v-if="isProgressBlocked" class="lock-icon">
			<ASvg name="vip-club/lock" />
		</div>
	</div>
</template>

<style scoped lang="scss">
.status-btn {
	position: relative;
	left: -12px;
	cursor: pointer;

	@include media-breakpoint-down(lg) {
		top: 2px;
		left: 0;
	}

	.rank {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, calc(-50% - 2px));
		z-index: 2;
	}

	.lock-icon {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		bottom: 16px;
		right: 10px;
		width: 20px;
		height: 20px;
		background: var(--neutral-800);
		border-radius: 50%;
		font-size: 12px;
		filter: drop-shadow(0px 2px 4px rgba(2, 6, 23, 0.06)) drop-shadow(0px 4px 6px rgba(2, 6, 23, 0.1));
		z-index: 3;
	}
}
</style>
