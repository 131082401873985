import type { v1, ExtractFromAPI } from "@netgame/openapi";

import useAppInitData from "./useAppInitData";
import useCountdown from "./useCountdown";
import useGetTournamentData from "./useGetTournamentData";
import useSubTournament from "./useSubTournament";

type NextTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/next-tournaments/", "get">;
type NextTournaments = NonNullable<NextTournamentsResponse["tournaments"]>;
type NextTournament = NonNullable<NextTournaments[number]>;
type NextWinners = NonNullable<NextTournament["winners"]>;

type PrevTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/tournaments/history/", "get">;
type PrevTournaments = NonNullable<PrevTournamentsResponse["tournaments"]>;
type PrevTournament = NonNullable<PrevTournaments[number]>;
type PrevWinners = NonNullable<PrevTournament["winners"]>;

type TournamentResponse = ExtractFromAPI<v1.paths, "/rest/tournament/", "get">;
type TournamentsData = NonNullable<TournamentResponse["data"]>;
type Winners = NonNullable<TournamentsData["winners"]>;

type ExtendWinners = NextWinners & PrevWinners & Winners;

type ModalOpen<K extends string, P> = (name: K, props?: P) => void;

const useTournamentData = <K extends string, P>({
	timerFormat,
	options = {},
	gameId = ref(),
	open = () => {}
}: {
	timerFormat?: string;
	options?: { immediate?: boolean; cached?: boolean };
	gameId?: Ref<number | undefined>;
	open?: ModalOpen<K, P>;
} = {}) => {
	const { data: appInitData } = useAppInitData();
	const { data: restTournamentData, refresh: refreshRestTournamentData } = useGetTournamentData({
		gameId: toRef(() => gameId.value),
		options
	});
	const { loadingSubscribtions, handleSubscribe } = useSubTournament();

	const tournamentData = computed(() => restTournamentData.value?.data);
	const tournamentExist = computed(() => restTournamentData.value?.isActiveStatus);
	const playerData = computed(() => restTournamentData.value?.playerData);
	const dateEnd = computed(() => tournamentData.value?.end);
	const { durationLeft, reset } = useCountdown({
		timestamp: dateEnd.value || "",
		formatToken: timerFormat ?? "HH[ H ]mm[ M ]ss[ S ]",
		onCountdownStop: refreshRestTournamentData
	});
	const initialWinners = computed(() => tournamentData.value?.winners || []);
	const roundsPlayed = computed(() => playerData.value?.roundsPlayed || 0);
	const rank = computed(() => playerData.value?.place || 0);
	const points = computed(() => playerData.value?.points);
	const activeStatus = computed(() => tournamentData.value?.isActive || !tournamentData.value?.isOpen);
	const defaultPrizes = computed(() => tournamentData.value?.prizes?.slice(0, 3));
	const subscribedStatus = computed(
		() =>
			tournamentData.value?.isSubscribed ||
			!!appInitData.value?.TournamentsSubscriptions?.includes(tournamentData.value?.id || null)
	);
	const entriesSum = computed(() => getEntries(tournamentData.value?.prizes as []));
	const coinsSum = computed(() => getCoins(tournamentData.value?.prizes as []));

	const winners = computed(() => {
		const userExist = initialWinners.value.some((user) => user.profile === playerData.value?.profile);
		const extendUser = {
			name: playerData.value?.name,
			place: playerData.value?.place,
			points: playerData.value?.points,
			position: playerData.value?.place,
			profile: playerData.value?.profile,
			rounds: playerData.value?.roundsPlayed
		} as Winners[number];
		return initialWinners.value
			.filter((user) => !!user.prizePlace)
			.map((user) => (user.profile === playerData.value?.profile ? { ...user, ...extendUser } : user))
			.concat(userExist ? [] : [{ ...extendUser }])
			.filter((user) => user.points !== 0);
	});

	const getLeaders = (data: ExtendWinners) => {
		if (!data?.length) {
			return [];
		}
		const currentUserIndex = data.findIndex((winner) => winner.profile === playerData.value?.profile) ?? -1;
		if (currentUserIndex <= 0) {
			return data.slice(0, 3);
		} else if (currentUserIndex === data.length - 1) {
			return data.slice(-3);
		} else {
			return data.slice(currentUserIndex - 1, currentUserIndex + 2);
		}
	};

	const leaders = computed(() => getLeaders(winners.value));

	const handleOpenPopup = (value: boolean) => {
		if (value) {
			open(
				"LazyOModalTournamentLeaderboard" as K,
				{
					id: Number(tournamentData.value?.history_id || tournamentData.value?.historyId)
				} as P
			);
			return;
		}
		open(
			"LazyOModalTournamentPrizes" as K,
			{
				title: tournamentData.value?.title,
				prizes: tournamentData.value?.prizes
			} as P
		);
	};

	const handleTournament = () => {
		if (subscribedStatus.value) {
			open("LazyOModalTournamentGames" as K);
			return;
		}
		if (!appInitData.value?.isGuest && tournamentData.value?.id) {
			open("LazyOModalTournamentGames" as K);
			handleSubscribe({
				id: tournamentData.value?.id,
				isSub: subscribedStatus.value
			});
		}
	};

	watch(dateEnd, () => {
		reset(dateEnd.value || "");
	});

	return {
		roundsPlayed,
		rank,
		points,
		activeStatus,
		defaultPrizes,
		subscribedStatus,
		entriesSum,
		coinsSum,
		winners,
		leaders,
		durationLeft,
		restTournamentData,
		playerData,
		loadingSubscribtions,
		tournamentExist,
		tournamentData,
		handleSubscribe,
		handleTournament,
		handleOpenPopup,
		refreshRestTournamentData,
		getLeaders
	};
};

export default useTournamentData;
