import { default as account6cvE9js2nmMeta } from "/app/apps/fortunewheelz/pages/account.vue?macro=true";
import { default as indexU8xivMZ269Meta } from "/app/apps/fortunewheelz/pages/affiliates/index.vue?macro=true";
import { default as partners_45accountbGKlpeF3AqMeta } from "/app/apps/fortunewheelz/pages/affiliates/partners-account.vue?macro=true";
import { default as how_45it_45worksM9Ybd3vFSWMeta } from "/app/apps/fortunewheelz/pages/how-it-works.vue?macro=true";
import { default as indexO8vsNFLjw9Meta } from "/app/apps/fortunewheelz/pages/index.vue?macro=true";
import { default as _91pageName_93Bn4wuFzTvOMeta } from "/app/apps/fortunewheelz/pages/issues/[pageName].vue?macro=true";
import { default as favoritessAX74q97rIMeta } from "/app/apps/fortunewheelz/pages/issues/favorites.vue?macro=true";
import { default as magic_45boxeshL4NYv6QW0Meta } from "/app/apps/fortunewheelz/pages/magic-boxes.vue?macro=true";
import { default as _91name_93gjJu3cakn0Meta } from "/app/apps/fortunewheelz/pages/page/[name].vue?macro=true";
import { default as prize_45dropsNBwlpMh96HMeta } from "/app/apps/fortunewheelz/pages/prize-drops.vue?macro=true";
import { default as promotionshF66CfpS2aMeta } from "/app/apps/fortunewheelz/pages/promotions.vue?macro=true";
import { default as referral_45infoas4dn6PE8VMeta } from "/app/apps/fortunewheelz/pages/referral-info.vue?macro=true";
import { default as scratch_45cardst2VdAkSUPVMeta } from "/app/apps/fortunewheelz/pages/scratch-cards.vue?macro=true";
import { default as tournamentsJfAt4njtRwMeta } from "/app/apps/fortunewheelz/pages/tournaments.vue?macro=true";
import { default as vipWgmSBmh4XlMeta } from "/app/apps/fortunewheelz/pages/vip.vue?macro=true";
import { default as wheel_45of_45treasurea4pK18TakvMeta } from "/app/apps/fortunewheelz/pages/wheel-of-treasure.vue?macro=true";
export default [
  {
    name: account6cvE9js2nmMeta?.name ?? "account___en",
    path: account6cvE9js2nmMeta?.path ?? "/account",
    meta: account6cvE9js2nmMeta || {},
    alias: account6cvE9js2nmMeta?.alias || [],
    redirect: account6cvE9js2nmMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/account.vue").then(m => m.default || m)
  },
  {
    name: indexU8xivMZ269Meta?.name ?? "affiliates___en",
    path: indexU8xivMZ269Meta?.path ?? "/affiliates",
    meta: indexU8xivMZ269Meta || {},
    alias: indexU8xivMZ269Meta?.alias || [],
    redirect: indexU8xivMZ269Meta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: partners_45accountbGKlpeF3AqMeta?.name ?? "affiliates-partners-account___en",
    path: partners_45accountbGKlpeF3AqMeta?.path ?? "/affiliates/partners-account",
    meta: partners_45accountbGKlpeF3AqMeta || {},
    alias: partners_45accountbGKlpeF3AqMeta?.alias || [],
    redirect: partners_45accountbGKlpeF3AqMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/affiliates/partners-account.vue").then(m => m.default || m)
  },
  {
    name: how_45it_45worksM9Ybd3vFSWMeta?.name ?? "how-it-works___en",
    path: how_45it_45worksM9Ybd3vFSWMeta?.path ?? "/how-it-works",
    meta: how_45it_45worksM9Ybd3vFSWMeta || {},
    alias: how_45it_45worksM9Ybd3vFSWMeta?.alias || [],
    redirect: how_45it_45worksM9Ybd3vFSWMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: indexO8vsNFLjw9Meta?.name ?? "index___en",
    path: indexO8vsNFLjw9Meta?.path ?? "/",
    meta: indexO8vsNFLjw9Meta || {},
    alias: indexO8vsNFLjw9Meta?.alias || [],
    redirect: indexO8vsNFLjw9Meta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93Bn4wuFzTvOMeta?.name ?? "issues-pageName___en",
    path: _91pageName_93Bn4wuFzTvOMeta?.path ?? "/issues/:pageName()",
    meta: _91pageName_93Bn4wuFzTvOMeta || {},
    alias: _91pageName_93Bn4wuFzTvOMeta?.alias || [],
    redirect: _91pageName_93Bn4wuFzTvOMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/issues/[pageName].vue").then(m => m.default || m)
  },
  {
    name: favoritessAX74q97rIMeta?.name ?? "issues-favorites___en",
    path: favoritessAX74q97rIMeta?.path ?? "/issues/favorites",
    meta: favoritessAX74q97rIMeta || {},
    alias: favoritessAX74q97rIMeta?.alias || [],
    redirect: favoritessAX74q97rIMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/issues/favorites.vue").then(m => m.default || m)
  },
  {
    name: magic_45boxeshL4NYv6QW0Meta?.name ?? "magic-boxes___en",
    path: magic_45boxeshL4NYv6QW0Meta?.path ?? "/magic-boxes",
    meta: magic_45boxeshL4NYv6QW0Meta || {},
    alias: magic_45boxeshL4NYv6QW0Meta?.alias || [],
    redirect: magic_45boxeshL4NYv6QW0Meta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/magic-boxes.vue").then(m => m.default || m)
  },
  {
    name: _91name_93gjJu3cakn0Meta?.name ?? "page-name___en",
    path: _91name_93gjJu3cakn0Meta?.path ?? "/page/:name()",
    meta: _91name_93gjJu3cakn0Meta || {},
    alias: _91name_93gjJu3cakn0Meta?.alias || [],
    redirect: _91name_93gjJu3cakn0Meta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/page/[name].vue").then(m => m.default || m)
  },
  {
    name: prize_45dropsNBwlpMh96HMeta?.name ?? "prize-drops___en",
    path: prize_45dropsNBwlpMh96HMeta?.path ?? "/prize-drops",
    meta: prize_45dropsNBwlpMh96HMeta || {},
    alias: prize_45dropsNBwlpMh96HMeta?.alias || [],
    redirect: prize_45dropsNBwlpMh96HMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/prize-drops.vue").then(m => m.default || m)
  },
  {
    name: promotionshF66CfpS2aMeta?.name ?? "promotions___en",
    path: promotionshF66CfpS2aMeta?.path ?? "/promotions",
    meta: promotionshF66CfpS2aMeta || {},
    alias: promotionshF66CfpS2aMeta?.alias || [],
    redirect: promotionshF66CfpS2aMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/promotions.vue").then(m => m.default || m)
  },
  {
    name: referral_45infoas4dn6PE8VMeta?.name ?? "referral-info___en",
    path: referral_45infoas4dn6PE8VMeta?.path ?? "/referral-info",
    meta: referral_45infoas4dn6PE8VMeta || {},
    alias: referral_45infoas4dn6PE8VMeta?.alias || [],
    redirect: referral_45infoas4dn6PE8VMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/referral-info.vue").then(m => m.default || m)
  },
  {
    name: scratch_45cardst2VdAkSUPVMeta?.name ?? "scratch-cards___en",
    path: scratch_45cardst2VdAkSUPVMeta?.path ?? "/scratch-cards",
    meta: scratch_45cardst2VdAkSUPVMeta || {},
    alias: scratch_45cardst2VdAkSUPVMeta?.alias || [],
    redirect: scratch_45cardst2VdAkSUPVMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/scratch-cards.vue").then(m => m.default || m)
  },
  {
    name: tournamentsJfAt4njtRwMeta?.name ?? "tournaments___en",
    path: tournamentsJfAt4njtRwMeta?.path ?? "/tournaments",
    meta: tournamentsJfAt4njtRwMeta || {},
    alias: tournamentsJfAt4njtRwMeta?.alias || [],
    redirect: tournamentsJfAt4njtRwMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/tournaments.vue").then(m => m.default || m)
  },
  {
    name: vipWgmSBmh4XlMeta?.name ?? "vip___en",
    path: vipWgmSBmh4XlMeta?.path ?? "/vip",
    meta: vipWgmSBmh4XlMeta || {},
    alias: vipWgmSBmh4XlMeta?.alias || [],
    redirect: vipWgmSBmh4XlMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/vip.vue").then(m => m.default || m)
  },
  {
    name: wheel_45of_45treasurea4pK18TakvMeta?.name ?? "wheel-of-treasure___en",
    path: wheel_45of_45treasurea4pK18TakvMeta?.path ?? "/wheel-of-treasure",
    meta: wheel_45of_45treasurea4pK18TakvMeta || {},
    alias: wheel_45of_45treasurea4pK18TakvMeta?.alias || [],
    redirect: wheel_45of_45treasurea4pK18TakvMeta?.redirect,
    component: () => import("/app/apps/fortunewheelz/pages/wheel-of-treasure.vue").then(m => m.default || m)
  }
]