const usePrizeDropInit = () => {
	const { t } = useT();
	const { open } = useAppModals();
	const { isUserInGame } = useGameModal();

	const nextPrize = useState<number>("prize-drop-timer", () => 0);
	const isSockedCame = ref(false);

	const showTimeToast = () => {
		isSockedCame.value = true;
	};

	const { resetPrizeDropTimer, toNextPrizeDurationLeft, toNextPrizeDuration } = usePrizeDrop();
	const { showToastDrop } = usePrizeDropToast();

	if (process.client && toNextPrizeDurationLeft?.value) {
		watch(
			() => toNextPrizeDuration.value,
			(value) => {
				nextPrize.value = value;

				if (isSockedCame.value && value === 40 && !isUserInGame.value) {
					showToastDrop();
				}
			}
		);
	}

	onMounted(() => {
		usePrizeDropSocket({
			t,
			open,
			showTimeToast,
			toNextPrizeDurationLeft,
			resetTimer: resetPrizeDropTimer
		});
	});
};

export default usePrizeDropInit;
