<script lang="ts" setup>
import type { GameModes } from "@/types";
withDefaults(
	defineProps<{
		isBlur?: boolean;
		withoutBtn?: boolean;
	}>(),
	{ isBlur: false, withoutBtn: false }
);
const { t } = useT();
const route = useRoute();
const { closeModal } = useGameModal();
const { tourPoints, entries, winnings } = useBalance();
const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();

const handleSwitchToMode = async (mode: GameModes) => {
	dispatchGAEvent({
		event: "click_button",
		location: "header",
		button_name: isSweepStakes.value ? "switch_tourney" : "switch_promotional"
	});
	await handleSwitchGameMode(mode);
};

const handleHiwClick = () => {
	if (route.query.game) {
		closeModal();
	}
	navigateTo("/how-it-works/");
};
</script>
<template>
	<APopper placement="bottom">
		<div class="balance-switcher" data-tid="header-balances" :class="{ singe: withoutBtn }">
			<MPrizeFund
				id="entries-container"
				:variant="isSweepStakes ? 'entries' : 'coins'"
				is-svg
				:iconSize="16"
				:icon="isSweepStakes ? 'entries' : 'coins'"
			>
				<div v-if="isSweepStakes">
					<AText class="text-chofu" :modifiers="['semibold', 'ellipsis']" as="div">
						<ClientOnly>
							<AAnimationNumberCounter :number="Number(entries)" />
						</ClientOnly>
					</AText>
					<AText variant="ternopil" class="text-cairo" :modifiers="['uppercase']" as="div">
						<ClientOnly>
							{{ t("win") }}:<AAnimationNumberCounter :number="Number(winnings ?? 0)" :decimals="2" />
						</ClientOnly>
					</AText>
				</div>
				<AText v-else class="text-caracas coins" :modifiers="['semibold', 'ellipsis']" as="div" data-tid="show-balance">
					<AAnimationNumberCounter :number="Number(tourPoints)" />
				</AText>
				<ASvg class="arrow-icon" data-tid="open-balances-list" name="header/arrow-balance" filled />
			</MPrizeFund>
		</div>
		<template #content="{ close }">
			<div :class="['item', { selected: !isSweepStakes }]" @click="handleSwitchToMode('TournamentPoints'), close()">
				<MPrizeFund variant="coins" is-svg :iconSize="20" icon="coins">
					<AText :modifiers="['semibold']">{{ t("Gold Coins") }}</AText>
				</MPrizeFund>
				<AText
					class="item-balance text-caracas"
					:modifiers="['semibold', 'right', 'condensed']"
					as="div"
					data-tid="points-coins"
				>
					{{ numberFormat(tourPoints) }}
				</AText>
			</div>
			<div :class="['item', { selected: isSweepStakes }]" @click="handleSwitchToMode('SweepStakes'), close()">
				<MPrizeFund variant="entries" is-svg :iconSize="20" icon="entries">
					<AText :modifiers="['semibold']">{{ t("Fortune Coins") }}</AText>
				</MPrizeFund>
				<div class="item-balance">
					<AText :modifiers="['semibold', 'right']" class="text-chofu" as="div" data-tid="entries-coins">
						{{ numberFormat(entries) }}
					</AText>
					<AText
						class="text-cairo"
						variant="topeka"
						:modifiers="['right', 'normal', 'uppercase']"
						as="div"
						data-tid="winnings-coins"
					>
						{{ t("win") }}:{{ numberFormat(winnings) }}
					</AText>
				</div>
			</div>
			<template v-if="!withoutBtn">
				<div class="divider" />
				<AText :modifiers="['center', 'link', 'underline']" as="div" data-tid="info-link" @click="handleHiwClick">
					{{ t("How it works") }}?
				</AText>
			</template>
		</template>
	</APopper>
</template>
<style scoped lang="scss">
.balance-switcher {
	height: 40px;
	background: var(--craiova);
	padding: 0 gutter(1.5);
	border-radius: 8px 0 0 8px;
	margin-right: gutter(-2.5);
	display: flex;
	align-items: center;
	cursor: pointer;

	&.singe {
		border-radius: 8px;
		animation: pulse 2.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite;
	}
	.arrow-icon {
		font-size: 20px;
		margin: 0 gutter(0.5);
	}
	.ellipsis {
		min-width: 76px;
		max-width: 110px;
	}
	@include media-breakpoint-down(sm) {
		:deep(.prize) {
			gap: gutter(0.5);
		}
	}
}
:deep(.popper) {
	--popper-theme-background-color: var(--craiova);
	--popper-theme-background-color-hover: var(--craiova);
	width: 294px;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	gap: gutter(1);
	.divider {
		width: 100%;
		height: 1px;
		background: var(--changchun);
	}
	.item {
		padding: 0 gutter(1.5);
		height: 36px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: gutter(1);
		border-radius: 8px;
		transition: all 200ms ease-in-out;
		&.selected {
			background: var(--chandigarh);
		}
		&:not(.selected) {
			cursor: pointer;
			&:hover {
				background: var(--casablanca);
			}
		}
	}
}
.is-blurred {
	transition: 0.2s filter 8s ease-in-out;
	filter: blur(4px);
	&:hover {
		filter: blur(0);
		transition-delay: 0s;
		@include media-breakpoint-down(lg) {
			filter: blur(4px);
			transition-delay: 8s;
		}
	}
	&.out {
		filter: blur(0);
		transition-delay: 0s;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 var(--caracas);
	}

	70% {
		box-shadow: 0 0 13px 0 var(--caracas);
	}

	100% {
		box-shadow: 0 0 0 0 var(--caracas);
	}
}
</style>
