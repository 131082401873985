<script lang="ts" setup>
import { useStorage } from "@vueuse/core";

const emit = defineEmits<{
	(event: "toggleSidebar"): void;
	(event: "scrollToTop"): void;
}>();

const props = withDefaults(
	defineProps<{
		show: boolean;
	}>(),
	{ show: false }
);

const { t } = useT();
const { open } = useAppModals();
const { isMobile } = useDevice();
const { logoutUser } = useLogout();
const { isSweepStakes } = useSwitchMode();
const loginGuardFunction = useLoginGuard();
const { data: appInitData } = useAppInitData();
const { homePromotions } = usePromotionsData();
const { handleSupportButtonClick } = useSupportChat();
const referralPopupCookie = useCookie("showReferralPopup");
const dailyWheelPopupStorage = useStorage("showedDailyWheelPopup", "");
const { activeStatus } = useTournamentData({ options: { cached: false, immediate: false } });

const asideRef = ref();
const isGuest = useIsGuest();
const DIVIDER_COLOR =
	"linear-gradient(90deg, rgba(var(--neutral-800-rgb), 0.13) 0%, var(--neutral-800) 48.28%, rgba(var(--neutral-800-rgb), 0) 100%)";

const isEmailFirstContactAndNotConfirmed = computed(
	() => appInitData.value?.primaryContact === "email" && !appInitData.value?.emailConfirmed
);
const isShowEmailTopline = computed(() => !isGuest.value && isEmailFirstContactAndNotConfirmed.value);
const counterPromo = computed(() => homePromotions.value?.promotions?.length || 0);
const isShowAffiliate = computed(() => !isGuest.value && appInitData.value?.isAffiliate);
const isShowRedeem = computed(() => {
	if (appInitData.value?.redeem?.show === false) {
		return false;
	}
	if (isSweepStakes.value) {
		return appInitData.value?.redeem?.isEntries;
	}
	return appInitData.value?.redeem?.isCoins;
});

const handleRedeemClick = () => {
	loginGuardFunction({
		success: () => {
			window?.$store?.gaCash?.deposit?.({
				location: "redeem",
				step: "payments_method"
			});
			window?.$cash?.$router?.push?.("/cash/redeem");
		}
	});
};

const handleSupportClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "left_menu",
		button_name: "support"
	});
	handleSupportButtonClick();
};

const handleLogoutClick = async () => {
	await logoutUser();
	navigateTo("/", { external: true });
	dispatchGAEvent({ event: "logout", location: "sidebar" });
	dailyWheelPopupStorage.value = null;
	referralPopupCookie.value = null;
	if (!props.show) {
		return;
	}
	emit("toggleSidebar");
};

onClickOutside(asideRef, () => {
	if (!props.show) {
		return;
	}
	emit("toggleSidebar");
});
</script>

<template>
	<AOverlay
		data-tid="sidebar"
		:modifiers="['fixed']"
		:class="[{ show }, { 'is-logged': !isGuest, 'is-email-notify': isShowEmailTopline }]"
		:bg-color="isMobile ? 'var(--changshu)' : ''"
	>
		<aside ref="asideRef" data-tid="menu-container" @click="emit('toggleSidebar')">
			<NuxtLink v-if="isGuest" to="/" class="logo" data-tid="logo" @click="emit('scrollToTop')">
				<NuxtImg
					class="logo-image"
					src="/nuxt-img/logo-header@2x.png"
					alt="logo"
					format="avif"
					width="192"
					height="128"
					loading="lazy"
				/>
			</NuxtLink>

			<section class="features">
				<template v-if="!isGuest">
					<LazyOWheelOfTreasureSideBar v-if="appInitData?.depositStreak?.isActive" />
				</template>
				<MSidebarWheelOfFortune />
				<LazyOBingoSidebar v-if="appInitData?.bingo?.isActive" />
				<template v-if="!isGuest">
					<LazyOIndependenceSeasonProgress
						v-if="appInitData?.season?.isEnabled"
						:currentLevel="appInitData?.season?.currentLevel"
						:totalProgressPercents="appInitData?.season?.totalProgressPercents"
						@click="open('LazyOModalSeasonCards')"
					/>
					<LazyOScratchCardsSidebar v-if="appInitData?.scratchCardLottery?.isActive" />
					<LazyOMagicBoxesSidebarPumpkin v-if="appInitData?.magicBox?.isActive" />
					<LazyMPrizeDropsSidebar v-if="!isGuest && appInitData?.prizeDrops?.isActive" />
				</template>
			</section>

			<ADivider :bg-color="DIVIDER_COLOR" />

			<section v-if="isGuest" class="auth">
				<AButton
					variant="primary"
					size="lg"
					class="signup"
					data-tid="header-register-btn"
					@click="open('LazyOModalSignup', { location: 'left_menu' })"
				>
					<AText variant="toledo" :modifiers="['medium']">{{ t("Sign Up") }}</AText>
				</AButton>
				<AButton
					variant="secondary"
					size="lg"
					class="login"
					data-tid="header-login-btn"
					@click="open('LazyOModalLogin', { location: 'left_menu' })"
				>
					<AText variant="toledo" :modifiers="['medium']">{{ t("Log In") }}</AText>
				</AButton>
			</section>

			<ADivider v-if="isGuest" :bg-color="DIVIDER_COLOR" />

			<section class="games">
				<MSidebarLink link="/issues/popular-games/" icon="cherry" text="Games" tid="header-top-games" />

				<MSidebarLink v-if="!isGuest" link="/promotions/" icon="promotions" text="Promotions" tid="header-promotions">
					<ABadge v-if="counterPromo > 0" :size="14" background="var(--cebal)">
						<AText variant="ternopil" :modifiers="['semibold', 'condensed']" as="div">
							{{ counterPromo }}
						</AText>
					</ABadge>
				</MSidebarLink>

				<MSidebarLink guest link="/tournaments/" icon="tournaments" text="Tournaments" tid="header-tournaments">
					<ABadge v-if="activeStatus" :size="14" background="var(--cebal)">
						<AText variant="ternopil" :modifiers="['semibold', 'condensed']" as="div"> 1 </AText>
					</ABadge>
				</MSidebarLink>
				<MSidebarLink v-if="appInitData?.rankLeague?.enabled" link="/vip/" icon="vip" text="VIP Club">
					<ABadge variant="info" background="rgba(var(--gray-950-rgb), 0.4)" autosize>
						<AText class="text-caracas" variant="ternopil" :modifiers="['semibold', 'uppercase']" as="div">
							{{ t("up to 20%") }}
						</AText>
					</ABadge>
				</MSidebarLink>
				<MSidebarLink
					v-if="!isGuest"
					link="/referral-info/"
					icon="referral"
					text="Refer a friend"
					tid="header-referral-info"
				/>
			</section>

			<ADivider v-if="!isGuest" :bg-color="DIVIDER_COLOR" />

			<section v-if="!isGuest" class="account">
				<MSidebarLink link="/account/" icon="account" text="Account" tid="header-account" />
				<MSidebarLink v-if="isShowRedeem" icon="redeem" text="Redeem" tid="header-redeem" @click="handleRedeemClick" />
				<MSidebarLink link="/how-it-works/" icon="info" text="How it works" tid="header-how-it-works" />
			</section>

			<ADivider :bg-color="DIVIDER_COLOR" />
			<section class="support">
				<MSidebarLink
					v-if="!isGuest"
					guest
					:link="isShowAffiliate ? '/affiliates/partners-account/' : '/affiliates/'"
					:icon="isShowAffiliate ? 'partner-account' : 'affiliate'"
					:text="isShowAffiliate ? 'Partner’s account' : 'Affiliate program'"
				/>

				<MSidebarLink icon="support" text="Support" tid="header-support" @click="handleSupportClick" />
			</section>

			<template v-if="!isGuest">
				<ADivider :bg-color="DIVIDER_COLOR" />
				<MSidebarLink class="section-out" icon="logout" text="Logout" tid="header-logout" @click="handleLogoutClick" />
			</template>
		</aside>
	</AOverlay>
</template>

<style scoped lang="scss">
.overlay {
	z-index: 100;
	width: 100%;
	display: none;

	.logo-image {
		width: 96px;
		height: auto;
	}

	@include media-breakpoint-up(lg) {
		display: block;
		width: var(--o-sidebar-width);
		margin: 16px 24px 16px;

		&.is-logged {
			margin-top: 96px;
		}

		&.is-email-notify {
			margin-top: 144px;
		}
	}

	&.show {
		display: block;
	}
}

aside {
	background: var(--gray-900);
	width: var(--o-sidebar-width);
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 16px 10px;
	overflow-x: hidden;
	overflow-y: auto;
	@include scrollbar(2px, 2px, var(--primary-400), transparent, transparent);

	@include media-breakpoint-up(lg) {
		border-radius: 12px;
	}

	a {
		display: inline-flex;
		text-decoration: none;
	}
}
.is-logged aside {
	gap: 12px;
}
.logo {
	position: relative;
	display: inline-block;
	margin: 0 auto;
	&-image {
		@include media-breakpoint-down(sm) {
			width: 100px;
			height: auto;
		}
	}
}

.auth {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.features {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-bottom: 8px;
}
.badge {
	position: absolute;
	right: 0;
}
</style>
