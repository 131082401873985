<script lang="ts" setup>
const loginGuard = useLoginGuard();

const { open } = useAppModals();
const { magicBoxesIsActive } = useFeaturesIsActive();
const { firstItemData } = useScretchCardData({
	immediate: magicBoxesIsActive.value
});

const valueProgress = computed(
	() => ((firstItemData.value?.progress?.pointsComplete ?? 0) * 100) / (firstItemData.value?.progress?.points ?? 0)
);

const handleOpenModalClick = () => {
	loginGuard({
		success() {
			navigateTo("/magic-boxes");
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div class="pumpkin-btn flex-center" @click="handleOpenModalClick">
		<NuxtImg
			class="pumpkin"
			src="/nuxt-img/magic-boxes/magic-pumpkins/reward.png"
			loading="lazy"
			format="webp"
			alt="progress"
			width="100"
			height="100"
		/>
		<AProgressCircle
			class="pumpkin-progress"
			:value="valueProgress"
			color="#393939"
			colorValue="#A4F91A"
			linecap="round"
		/>
	</div>
</template>

<style lang="scss" scoped>
.pumpkin-btn {
	width: 48px;
	height: 48px;
	background: #272727;
	border-radius: 50%;
	z-index: 99;
	cursor: pointer;
	margin: 4px -9px 0 0;
	transform: scale(1.2);
}
.pumpkin-progress {
	position: absolute;
	top: -10px;
	left: -10px;
}
.pumpkin {
	width: 32px;
	height: auto;
	margin: 8px 0 0 0;
}
</style>
