const useStatusData = () => {
	const { data, refresh } = useAsyncFetch({
		path: "/rest/page/rank-league-info/",
		method: "get",
		options: {
			server: true
		}
	});

	const statusData = computed(() => data?.value?.payload);

	const currentProgress = computed(() => statusData?.value?.currentProgress);

	return {
		data,
		statusData,
		currentProgress,
		refresh
	};
};

export default useStatusData;
