import useAsyncFetch from "./useAsyncFetch";
import useFreeSpins from "./useFreeSpins";
import useHomeData from "./useHomeData";
import useSockets from "./useSockets";

type SocketFreeSpinData = {
	type: string;
	action: string;
	data: {
		gameId?: number;
		spinsRemain?: number;
		spinsTotal?: number;
	};
};

const useFreeSpinSockets = () => {
	const { add, remove } = useSockets();
	const freeSpins = useFreeSpins();
	const { refresh: refreshHomePageData } = useHomeData({ immediate: false });
	const { refresh: refreshPromotionsPageData } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: {
			immediate: false
		}
	});
	const freeSpinSocketData = useState("freeSpinSocketData");
	const spinsData: Ref<SocketFreeSpinData | undefined> = ref();

	add("freeSpin", (data: SocketFreeSpinData) => {
		spinsData.value = data;
		freeSpinSocketData.value = data.data;
		freeSpins.value.spinsRemain = data?.data?.spinsRemain || 0;

		if (data.action === "bet" && data.data.spinsRemain === 0) {
			refreshHomePageData();
			refreshPromotionsPageData();
		}
	});

	onUnmounted(() => {
		remove("freeSpin");
	});

	return {
		spinsData
	};
};

export default useFreeSpinSockets;
