<script lang="ts" setup>
const { open } = useAppModals();
const { data: appInitData } = useAppInitData({ immediate: false });
const loginGuard = useLoginGuard();
const valueProgress = computed(() => appInitData.value?.season?.totalProgressPercents);
const currentLevel = computed(() => appInitData.value?.season?.currentLevel || 1);
const handleOpenSeason = () => {
	loginGuard({
		success() {
			open("LazyOModalSeasonCards");
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div class="season-btn" @click="handleOpenSeason">
		<AText class="current-level" :modifiers="['center', 'bold']" as="div">{{ currentLevel }}</AText>
		<NuxtImg
			src="/nuxt-img/seasons/independence-day/mini-progress.png"
			class="prize-icon"
			format="avif"
			width="92"
			height="92"
			alt="prize icon"
			loading="lazy"
		/>
		<AProgressCircle
			class="season-progress"
			color="var(--chandigarh)"
			colorValue="var(--ciamis)"
			:size="80"
			:value="valueProgress"
		/>
	</div>
</template>

<style scoped lang="scss">
.season-btn {
	width: 56px;
	height: 56px;
	background: var(--cheng);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
	cursor: pointer;
}

.season-progress {
	position: absolute;
	top: -12px;
	left: -12px;
}

.prize-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	width: 46px;
	height: 46px;
	transform: translate(-50%, -50%);
}

.current-level {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(calc(-50% - 0.5px), -50%);
	color: #fffccc;
	text-shadow: 0 0.983px 0 #4f170c;
	z-index: 2;
}
</style>
