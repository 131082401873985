import { apiClient } from "@netgame/openapi";
import dayjs from "dayjs";

interface IUpdateCashback {
	status: string;
	availableAfterDateTime: string | null;
	availableAfterDay: string;
}

const useStatus = () => {
	const { open } = useAppModals();
	const { refresh: refreshHomePage } = useHomeData({ immediate: false });
	const { promotionsData, refresh: refreshPromo } = usePromotionsData();
	const { data: appInitData } = useAppInitData();

	const STATUS_FORM_LINK =
		"https://docs.google.com/forms/d/1QgW1dMc-fpfYHKyjtbOqu9Sf7rzjub8tBKzRQhhExG8/viewform?ts=66f3cfcf&edit_requested=true";

	const weeklyCashbackData = computed(
		() => promotionsData.value?.promotions.find((item) => item?.type === "rankLeagueCashback")?.data
	);

	const weeklyCashbackTime = computed(() => weeklyCashbackData.value?.availableAfterDateTime);

	const { durationLeft, duration, reset } = useCountdown({
		timestamp: dayjs(weeklyCashbackTime.value),
		formatToken: "D[D:]H[H:]mm[M]"
	});

	const unwatchTimer = watch(
		() => weeklyCashbackData.value?.availableAfterDateTime,
		(value) => {
			if (value) {
				reset(value);
			}
		}
	);

	const resetWeeklyCashbackTimer = () => {
		if (weeklyCashbackData.value?.availableAfterDateTime) {
			reset(weeklyCashbackData.value?.availableAfterDateTime);
		}
	};

	const durationLeftDisplay = computed(() => (duration.value > 0 ? durationLeft.value : "0D:0H:0M"));

	const hasWeeklyReward = computed(
		() => !!weeklyCashbackData.value?.currentReward && weeklyCashbackData.value?.currentReward > 0
	);

	const nextSectionTitle = computed(
		() =>
			weeklyCashbackData.value?.sections?.find((item) => item?.weeklyReward === weeklyCashbackData.value?.nextReward)
				?.title
	);

	const currentSection = computed(() => appInitData.value?.rankLeague?.currentSection);
	const currentLevel = computed(() => appInitData.value?.rankLeague?.currentLevel);
	const isProgressBlocked = computed(() => appInitData.value?.rankLeague?.progressBlockedReason);
	const isLastLevel = computed(() => appInitData.value?.rankLeague?.playerFullCompletedLeague);
	const totalPoints = computed(() => appInitData.value?.rankLeague?.sumAllLevelsPoints);
	const sectionsCount = computed(() => appInitData.value?.rankLeague?.sectionsCount);

	const valueProgress = computed(
		() =>
			currentLevel.value?.currentExperience &&
			currentLevel.value?.neededExperience &&
			Math.trunc((currentLevel.value?.currentExperience / currentLevel.value?.neededExperience) * 100)
	);

	const claimIsActive = computed(() => weeklyCashbackData?.value?.status === "available");

	const updateCashback = ({ status, availableAfterDateTime, availableAfterDay }: IUpdateCashback) => {
		const cashbackPromo = promotionsData.value?.promotions.find((item) => item?.type === "rankLeagueCashback");
		if (!cashbackPromo) {
			return;
		}

		if (weeklyCashbackData && weeklyCashbackData.value) {
			const oldTimer = weeklyCashbackData.value.availableAfterDateTime;

			weeklyCashbackData.value.availableAfterDay = availableAfterDay;
			weeklyCashbackData.value.status = status;
			weeklyCashbackData.value.availableAfterDateTime = availableAfterDateTime || weeklyCashbackTime.value;

			if (!!availableAfterDateTime && availableAfterDateTime !== oldTimer) {
				resetWeeklyCashbackTimer();
			}
		}
	};

	const claimWeeklyReward = async () => {
		try {
			await apiClient({
				path: "/rest/cashback/pay",
				method: "post",
				options: {
					onResponse: ({ response }) => {
						const prize = response?._data?.data;

						if (prize) {
							open("LazyOModalVipClubClaimReward", {
								prize,
								nextSectionTitle: nextSectionTitle.value || ""
							});
						}
					}
				}
			});

			await refreshPromo();
			await refreshHomePage();
		} catch (e) {
			console.error("Error :>> ", e);
		}
	};

	onUnmounted(() => {
		unwatchTimer();
	});

	return {
		sectionsCount,
		totalPoints,
		isLastLevel,
		currentSection,
		currentLevel,
		isProgressBlocked,
		valueProgress,
		weeklyCashbackData,
		durationLeft,
		hasWeeklyReward,
		claimIsActive,
		weeklyCashbackTime,
		duration,
		durationLeftDisplay,
		STATUS_FORM_LINK,
		claimWeeklyReward,
		updateCashback,
		resetWeeklyCashbackTimer
	};
};

export default useStatus;
