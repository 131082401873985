import useBingoData from "./useBingoData";
import useSockets from "./useSockets";

const useBingoSocket = () => {
	const { updateBingoData } = useBingoData({ immediate: false });
	const { add, remove } = useSockets();

	add("bingo", ({ action }: { action: string }) => {
		if (action === "show") {
			updateBingoData();
		}
	});

	onUnmounted(() => {
		remove("bingo");
	});
};

export default useBingoSocket;
