<script setup lang="ts">
const emit = defineEmits<{
	(event: "scrollToTop"): void;
}>();

const { y } = useWindowScroll();

const transparentHeader = computed(() => y.value > 0);
</script>

<template>
	<header :class="{ transparent: !transparentHeader }">
		<NuxtLink to="/" class="logo-image" @click="emit(`scrollToTop`)">
			<NuxtImg
				src="/nuxt-img/logo-header@2x.png"
				format="webp"
				width="130"
				height="88"
				class="logo"
				alt="logo"
				loading="lazy"
			/>
		</NuxtLink>
	</header>
</template>

<style scoped lang="scss">
header {
	background: var(--theme-dark);
	border-bottom: 1px solid var(--neutral-800);
	padding: 10px 8px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;

	.logo {
		height: 44px;
		width: 65px;
	}

	.logo-image {
		position: relative;

		@include media-breakpoint-up(lg) {
			display: inline-block;
			margin: 0 auto;
		}
	}
	&.transparent {
		background: transparent;
		border-bottom-color: transparent;
	}
}
</style>
