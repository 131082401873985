<script lang="ts" setup>
const props = defineProps<{
	guest?: boolean;
	link?: string;
	icon?: string;
	text?: string;
	tid?: string;
}>();

const { t } = useT();
const isGuest = useIsGuest();
const { open } = useAppModals();
const loginGuardFunction = useLoginGuard();

const handleSighUpOpen = () => {
	if (props.guest) {
		loginGuardFunction({
			fail: () => {
				open("LazyOModalSignup", { location: "left_menu" });
			}
		});
	}
};
</script>

<template>
	<div v-if="(guest && isGuest) || !link" class="link" :data-tid="tid" @click="handleSighUpOpen">
		<ASvg :name="`menu/${icon}`" class="link-icon" />
		<AText v-if="text" variant="toledo" :modifiers="['semibold']">
			{{ t(text) }}
		</AText>
		<slot />
	</div>
	<NuxtLink v-else :to="link" class="link" active-class="active" :data-tid="tid">
		<ASvg :name="`menu/${icon}`" class="link-icon" />
		<AText v-if="text" variant="toledo" :modifiers="['semibold']">
			{{ t(text) }}
		</AText>
		<slot />
	</NuxtLink>
</template>

<style scoped lang="scss">
.link {
	position: relative;
	z-index: 1;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 8px 10px;
	cursor: pointer;
	transition: color 0.3s linear;
	color: var(--neutral-50);
	&-icon {
		transition: color 0.3s linear;
		color: var(--primary-400);
		font-size: 20px;
	}
	&:hover {
		color: var(--neutral-500);
	}
	&.active {
		color: var(--primary-400);
	}
}
section.account {
	.link-icon {
		color: var(--secondary-400);
	}
	.link.active {
		.link-icon {
			color: var(--primary-400);
		}
	}
}

section.support {
	.link {
		color: var(--neutral-400);
		text-transform: uppercase;
		.toledo {
			font-size: 12px;
		}
		.link-icon {
			color: var(--neutral-600);
		}
		&:hover {
			color: var(--neutral-300);
			.link-icon {
				color: var(--neutral-300);
			}
		}
		&.active {
			color: var(--primary-400);
			.link-icon {
				color: var(--primary-400);
			}
		}
	}
}
.section-out {
	&.link {
		color: var(--danger-400);
		text-transform: uppercase;
		.toledo {
			font-size: 12px;
		}
		&:hover {
			color: var(--primary-400);
			.link-icon {
				color: var(--primary-400);
			}
		}
	}
	.link-icon {
		color: var(--danger-400);
	}
}
</style>
