<script setup lang="ts">
useHead({
	title: "Page not found - Fortune Wheelz Social Casino: Play for Free"
});

const { t } = useT();
const isGuest = useIsGuest();
const [isShow, toggleSidebar] = useToggle(false);
const { isMobile } = useDevice();
const { data: appInitData } = useAppInitData();

const handleToggleSidebar = () => {
	toggleSidebar();
};

const handleError = () => {
	clearError({ redirect: "/" });
};
</script>
<template>
	<div class="error">
		<ClientOnly>
			<SupportChat />
			<OModals />
			<Cash v-if="!isGuest" />
		</ClientOnly>

		<ASvgCollection />
		<OHeader v-if="!isGuest" :class="['header', { 'is-logged': !isGuest }]" />
		<OHeaderGuest v-else :class="['header', { 'is-logged': !isGuest }]" />
		<OSidebar :show="isShow" class="menu" @toggle-sidebar="handleToggleSidebar" />

		<div class="content">
			<AText variant="tainan" class="title text-caracas" :modifiers="['bold', 'center']" as="h1">404</AText>
			<AText variant="tasikmalaya" :modifiers="['center']" class="info-text">{{ t("Page not found") }}</AText>
			<AButton
				variant="primary"
				class="back-home"
				:size="isMobile ? 'lg' : 'xl'"
				data-tid="error-return-home"
				@click="handleError"
			>
				<AText variant="turin" :modifiers="['medium']">{{ t("Go home") }}</AText>
			</AButton>
		</div>
		<OMobNavigation
			:isShowSeason="appInitData?.season?.isEnabled"
			class="mob-menu"
			@toggle-sidebar="handleToggleSidebar"
		/>
	</div>
</template>
<style lang="scss" scoped>
.error {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.title {
	line-height: 64px;
}

.menu {
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

header {
	display: flex;

	@include media-breakpoint-up(lg) {
		display: none;
		&.is-logged {
			display: flex;
		}
	}
}

.content {
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	gap: gutter(3);
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: var(--cambe) center center no-repeat;
	background-image: url("/nuxt-img/error-bg.jpg");
	background-size: cover;
	z-index: -1;

	@include media-breakpoint-down(md) {
		background-image: url("/nuxt-img/error-bg-mob.jpg");
		background-size: cover;
		gap: gutter(1);
	}
}

.header {
	width: 100%;
}

.error-img {
	max-width: 100%;
	display: block;
	height: auto;
	object-fit: cover;

	@include media-breakpoint-down(sm) {
		width: 100%;
		max-width: 264px;
	}
}

.back-home {
	width: 100%;
	max-width: 172px;
}

.info-text {
	@include media-breakpoint-down(md) {
		margin-bottom: gutter(2);
		font-size: 20px;
		line-height: 26px;
	}
}
</style>
