const useInitRegisterComplete = () => {
	const { open } = useAppModals();
	const guard = useIsRegistrationCompleteGuard();

	onMounted(() => {
		guard({
			success: () => {
				open("LazyOModalRegistrationComplete");
			}
		});
	});
};

export default useInitRegisterComplete;
