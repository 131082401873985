import type { ScratchCardsInfoData } from "@/types";

const TICKET_COLORS = {
	bronze: {
		dark: "#D06F09",
		light: "#FFB05F",
		border: "#7E360D",
		inner: "rgba(177, 0, 0, 0.8)",
		textShadow: "#A74301",
		winTextShadow: "#AC7714"
	},
	silver: {
		dark: "#A2AFBF",
		light: "#CBCDCF",
		border: "#66696C",
		inner: "rgba(7, 42, 97, 0.8)",
		textShadow: "#464646",
		winTextShadow: "#8B8C8E"
	},
	gold: {
		dark: "#F7B73E",
		light: "#FFCF72",
		border: "#916000",
		inner: "rgba(198, 130, 0, 0.8)",
		textShadow: "#A74301",
		winTextShadow: "#FAC34D"
	}
};

const SUMMER_THEME = true;

const useScretchCardData = ({
	immediate = true
}: { immediate?: boolean; timerFormat?: string; isOptimized?: boolean } = {}) => {
	const { data, refresh } = useAsyncFetch({
		path: "/rest/scratch-card-lottery/get/",
		method: "get",
		options: {
			immediate,
			cached: true,
			transform: (data) => {
				const success = data.success;
				return {
					...(data.data as ScratchCardsInfoData["data"]),
					success
				};
			}
		}
	});

	const activeCard = computed(() => {
		const indexReady = data.value?.cards?.findIndex((item) => item.status === "ready_for_activate") ?? -1;
		const indexAvaliable = data.value?.cards?.findIndex((item) => item.status === "available") ?? -1;
		if (indexReady !== -1) {
			return data.value?.cards?.[indexReady];
		}
		if (indexAvaliable !== -1) {
			return data.value?.cards?.[indexAvaliable];
		}
		return data.value?.cards?.[0];
	});

	const { durationLeft, reset: resetTimer } = useCountdown({
		timestamp: data.value?.finishedAt ?? "",
		formatToken: "DD[D ] HH[H ]mm[M ]",
		isOptimized: true
	});

	watch(
		() => data.value?.success,
		() => {
			resetTimer(data.value?.finishedAt);
		}
	);

	const firstItemData = computed(() => data?.value?.cards?.[0] || {});

	const { durationLeft: firstItemTimer, reset: resetFirstItemTimer } = useCountdown({
		timestamp: firstItemData.value?.timer || "",
		formatToken: "HH:mm:ss",
		isOptimized: true,
		onCountdownStop: immediate ? refresh : () => {}
	});

	watch(
		() => data.value?.cards,
		() => {
			resetFirstItemTimer(firstItemData.value?.timer);
		}
	);
	return { data, refresh, activeCard, durationLeft, TICKET_COLORS, SUMMER_THEME, firstItemData, firstItemTimer };
};

export default useScretchCardData;
