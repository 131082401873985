import useAsyncFetch from "./useAsyncFetch";

const useBingoData = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const BALLS = 10;

	const { data: bingoData, execute: updateBingoData } = useAsyncFetch({
		path: "/rest/bingo/check-player-status/",
		method: "get",
		options: {
			immediate
		}
	});

	const collectedBalls = computed(() => bingoData.value?.availablePoint || 0);
	const counterBalls = computed(() => `${collectedBalls.value}/${BALLS}`);
	const isCanPlay = computed(() => collectedBalls.value >= BALLS);

	const { data: bingoTicketsData, execute: getBingoTicketsData } = useAsyncFetch({
		path: "/rest/bingo/bingo-game-data/",
		method: "get",
		options: {
			immediate: false
		}
	});

	const { execute: applyPrize } = useAsyncFetch({
		path: "/rest/bingo/bingo-accrue-reward/",
		method: "post",
		options: {
			immediate: false
		},
		fetchOptions: {
			onResponse: () => {
				updateBingoData();
			}
		}
	});

	return {
		BALLS,
		bingoData,
		collectedBalls,
		counterBalls,
		isCanPlay,
		updateBingoData,
		getBingoTicketsData,
		bingoTicketsData,
		applyPrize
	};
};

export default useBingoData;
