import type { v1, ExtractFromAPI } from "@netgame/openapi";
import { toast } from "vue3-toastify";

import useAppInitData from "./useAppInitData";
import useGetTournamentData from "./useGetTournamentData";
import useGetTournamentsData from "./useGetTournamentsData";
import useSockets from "./useSockets";

type TournamentResponse = ExtractFromAPI<v1.paths, "/rest/tournament/", "get">;
type TournamentData = NonNullable<TournamentResponse["data"]>;
type TournamentWinners = NonNullable<TournamentData["winners"]>;
type TournamentPlayerData = NonNullable<TournamentResponse["playerData"]>;

type NextTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/next-tournaments/", "get">;
type NextTournamentsData = NonNullable<NextTournamentsResponse["tournaments"]>;
type NextTournaments = NextTournamentsData[number];

type PrevTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/tournaments/history/", "get">;
type PrevTournamentsData = NonNullable<PrevTournamentsResponse["tournaments"]>;
type PrevTournaments = PrevTournamentsData[number];

type SocketDataTournament = {
	type: string;
	action: string;
	bets_count: number;
	winners: TournamentWinners;
	historyId: string;
	next: Array<NextTournaments>;
	prev: Array<PrevTournaments>;
	playerData: TournamentPlayerData;
	data: {
		totalPoints: number;
		place: number;
	};
};

type ModalOpen<K extends string, P> = (name: K, props?: P) => void;

const showFinishRaceToast = <T extends (key: string) => string>({
	t,
	title,
	isMobile,
	onClick = () => {}
}: {
	t: T;
	title: string;
	isMobile: boolean;
	onClick?: () => void;
}) => {
	toast.success(
		`
			<div class="finish-race-title">${title} ${t("completed")}</div>
			<div class="finish-race-text">${t("Show Leaderboard")}</div>
			`,
		{
			toastId: "finish-race",
			icon: h("i", { class: "icon-race" }),
			dangerouslyHTMLString: true,
			theme: toast.THEME.DARK,
			position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_RIGHT,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 10000,
			onClick
		}
	);
};

const useRaceSockets = <T extends (key: string) => string, K extends string, P>({
	sliceRange = 5,
	showFinishedToast = false,
	t,
	openModal
}: {
	t: T;
	openModal: ModalOpen<K, P>;
	showFinishedToast?: boolean;
	sliceRange?: number;
}) => {
	const { data: appInitData } = useAppInitData();
	const { data: tournamentData, refresh } = useGetTournamentData({ options: { immediate: false } });
	const { data: tournamentsData, activeTab, activePath, limit } = useGetTournamentsData({ immediate: false });

	const { isMobile } = useDevice();
	const { add, remove } = useSockets();

	add("tournament", (data: SocketDataTournament) => {
		const historyId = tournamentData.value?.data?.historyId;

		if (historyId === null && data.action === "start") {
			refresh();
		}

		if (data.historyId && historyId !== data.historyId) {
			return;
		}

		if (data.action === "start" || data.action === "stop") {
			const userId = appInitData.value?.uuid;
			if (
				showFinishedToast &&
				data.action === "stop" &&
				data.winners?.findIndex((winner) => winner?.uuid === userId) > -1
			) {
				showFinishRaceToast({
					t,
					title: data?.prev[0]?.title || "",
					isMobile,
					onClick: () => openModal("" as K, { title: data?.prev[0]?.title, id: Number(data.historyId) } as P)
				});
			}

			refresh();

			if (data[activeTab.value] && tournamentsData.value?.[activePath.value]) {
				tournamentsData.value[activePath.value][`${limit.value}_0`] = {
					tournaments: data[activeTab.value as "next"].slice(0, sliceRange)
				};
			}
			if (tournamentData.value?.data) {
				tournamentData.value.data.winners = data.winners;
				return;
			}
		}

		if (data.action === "positions" && tournamentData.value?.data) {
			tournamentData.value.data.winners = data.winners;

			if (data.playerData && tournamentData.value?.playerData) {
				tournamentData.value.playerData = data.playerData;
			}
			return;
		}

		if (data.action === "bet" && tournamentData.value?.playerData) {
			tournamentData.value.playerData.roundsPlayed = data.bets_count;
			return;
		}

		if (data.action === "playerWin" && tournamentData.value?.playerData) {
			tournamentData.value.playerData.points = data.data.totalPoints;
			tournamentData.value.playerData.place = data.data.place;
		}
	});

	onUnmounted(() => {
		remove("tournament");
	});
};

export default useRaceSockets;
