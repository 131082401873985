<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const loginGuardFunction = useLoginGuard();

const handleWheelClick = () => {
	loginGuardFunction({
		success: () => {
			open("LazyOModalDailyWheel");
		},
		fail: () => {
			open("LazyOModalSignup", { location: "left_menu" });
		}
	});
};
</script>
<template>
	<div class="wheel-of-fortune" data-tid="header-fortune-wheel" @click="handleWheelClick">
		<AText :modifiers="['semibold']">
			<span>{{ t("Wheel of Fortune") }}</span>
		</AText>
		<div class="animated-wheel">
			<NuxtImg
				src="/nuxt-img/wheel-promo/wheel-base-leaves.png"
				format="avif"
				width="450"
				height="450"
				alt="leaves"
				class="leaves"
				loading="lazy"
			/>
			<AAnimationDrum>
				<NuxtImg
					src="/nuxt-img/wheel-promo/wheel-sectors.png"
					width="450"
					height="450"
					format="avif"
					alt="sectors"
					loading="lazy"
				/>
			</AAnimationDrum>
			<NuxtImg
				src="/nuxt-img/wheel-promo/wheel-pointers.png"
				format="avif"
				width="450"
				height="450"
				alt="coins"
				class="coins"
				loading="lazy"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.wheel-of-fortune {
	position: relative;
	padding: 14px 12px;
	background-color: var(--neutral-700);
	border-radius: 10px;
	cursor: pointer;

	.animated-wheel {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(15px, -50%);
		width: 80px;
		height: 80px;
		& img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
</style>
