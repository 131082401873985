<script lang="ts" setup>
const props = defineProps<{ antiClockWise?: boolean }>();
const degree1 = computed(() => (props.antiClockWise ? "-730deg" : "730deg"));
const degree2 = computed(() => (props.antiClockWise ? "-720deg" : "720deg"));
</script>

<template>
	<div class="animation-drum">
		<slot />
	</div>
</template>

<style lang="scss" scoped>
.animation-drum {
	width: 100%;
	height: 100%;
	animation: drum 16.5s infinite;
}
@keyframes drum {
	0%,
	100% {
		transform: rotate(0);
	}
	34% {
		transform: rotate(v-bind(degree1));
	}
	39.3939%,
	99.9999% {
		transform: rotate(v-bind(degree2));
	}
}
</style>
