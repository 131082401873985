import LazyOModalAccountChangePassword from "~/organizms/OModal/AccountChangePassword.vue";
import LazyOModalAccountEditContact from "~/organizms/OModal/AccountEditContact.vue";
import LazyOModalBingoHowItWorks from "~/organizms/OModal/Bingo/HowItWorks.vue";
import LazyOModalBingoPlay from "~/organizms/OModal/Bingo/Play.vue";
import LazyOModalBingoWelcome from "~/organizms/OModal/Bingo/Welcome.vue";
import LazyOModalCodeConfirm from "~/organizms/OModal/CodeConfirm.vue";
import LazyOModalDailyWheel from "~/organizms/OModal/DailyWheel.vue";
import LazyOModalDailyWheelRules from "~/organizms/OModal/DailyWheelRules.vue";
import LazyOModalDemoMode from "~/organizms/OModal/DemoMode.vue";
import LazyOModalEmailConfirm from "~/organizms/OModal/EmailConfirm.vue";
import LazyOModalForgotPassword from "~/organizms/OModal/ForgotPassword.vue";
import LazyOModalForgotPasswordSent from "~/organizms/OModal/ForgotPasswordSent.vue";
import LazyOModalGame from "~/organizms/OModal/Game.vue";
import LazyOModalGameVipOverview from "~/organizms/OModal/GameVipOverview.vue";
import LazyOModalIndependenceSeasonPrize from "~/organizms/OModal/IndependenceSeason/Prize.vue";
import LazyOModalInsufficientFunds from "~/organizms/OModal/InsufficientFunds.vue";
import LazyOModalInsufficientWithdrawal from "~/organizms/OModal/InsufficientWithdrawal.vue";
import LazyOModalLogin from "~/organizms/OModal/Login.vue";
import LazyOModalMagicBoxesHowItWorks from "~/organizms/OModal/MagicBoxes/HowItWorks.vue";
import LazyOModalMagicBoxesSelection from "~/organizms/OModal/MagicBoxes/Selection.vue";
import LazyOModalMagicBoxesWelcome from "~/organizms/OModal/MagicBoxes/Welcome.vue";
import LazyOModalPhoneConfirmation from "~/organizms/OModal/PhoneConfirmation.vue";
import LazyOModalPhoneConfirmationInfo from "~/organizms/OModal/PhoneConfirmationInfo.vue";
import LazyOModalPresetPackage from "~/organizms/OModal/PresetPackage.vue";
import LazyOModalPrizeDropsWin from "~/organizms/OModal/PrizeDrops/Win.vue";
import LazyOModalPromoInviteFriends from "~/organizms/OModal/PromoInviteFriends.vue";
import LazyOModalQrcode from "~/organizms/OModal/Qrcode.vue";
import LazyOModalReferralInfo from "~/organizms/OModal/ReferralInfo.vue";
import LazyOModalRegistrationComplete from "~/organizms/OModal/RegistrationComplete.vue";
import LazyOModalReRegistration from "~/organizms/OModal/ReRegistration.vue";
import LazyOModalScratchCardsInfo from "~/organizms/OModal/ScratchCards/Info.vue";
import LazyOModalScratchCardsTicketPlay from "~/organizms/OModal/ScratchCards/TicketPlay.vue";
import LazyOModalScratchCardsWelcome from "~/organizms/OModal/ScratchCards/Welcome.vue";
import LazyOModalScratchCardsYouWon from "~/organizms/OModal/ScratchCards/YouWon.vue";
import LazyOModalSeasonCards from "~/organizms/OModal/Season/Cards.vue";
import LazyOModalSeasonCongratulations from "~/organizms/OModal/Season/Congratulations.vue";
import LazyOModalSeasonTower from "~/organizms/OModal/Season/Tower.vue";
import LazyOModalSeasonWelcome from "~/organizms/OModal/Season/Welcome.vue";
import LazyOModalSignup from "~/organizms/OModal/Signup.vue";
import LazyOModalSuccessReferral from "~/organizms/OModal/SuccessReferral.vue";
import LazyOModalSwitchToSweeps from "~/organizms/OModal/SwitchToSweeps.vue";
import LazyOModalTermsDialog from "~/organizms/OModal/TermsDialog.vue";
import LazyOModalTournamentsLeaderboard from "~/organizms/OModal/Tournaments/Leaderboard.vue";
import LazyOModalTournamentsPrizes from "~/organizms/OModal/Tournaments/Prizes.vue";
import LazyOModalTournamentsRules from "~/organizms/OModal/Tournaments/Rules.vue";
import LazyOModalVipClubClaimReward from "~/organizms/OModal/VipClub/ClaimReward.vue";
import LazyOModalVipClubFinish from "~/organizms/OModal/VipClub/Finish.vue";
import LazyOModalVipClubInfo from "~/organizms/OModal/VipClub/Info.vue";
import LazyOModalVipClubLevelsInfo from "~/organizms/OModal/VipClub/LevelsInfo.vue";
import LazyOModalVipClubProfile from "~/organizms/OModal/VipClub/Profile.vue";
import LazyOModalVipClubSaveProgress from "~/organizms/OModal/VipClub/SaveProgress.vue";
import LazyOModalVipClubStatusUp from "~/organizms/OModal/VipClub/StatusUp.vue";
import LazyOModalWelcome from "~/organizms/OModal/Welcome.vue";
import LazyOModalWheelOfTreasureHowItWorks from "~/organizms/OModal/WheelOfTreasure/HowItWorks.vue";
import LazyOModalWheelOfTreasurePlayWheel from "~/organizms/OModal/WheelOfTreasure/PlayWheel.vue";
import LazyOModalWheelOfTreasureWelcome from "~/organizms/OModal/WheelOfTreasure/Welcome.vue";
import LazyOModalWithoutDepositBonus from "~/organizms/OModal/WithoutDepositBonus.vue";

export type Modals = {
	LazyOModalForgotPassword: InstanceType<typeof LazyOModalForgotPassword>["$props"];
	LazyOModalForgotPasswordSent: InstanceType<typeof LazyOModalForgotPasswordSent>["$props"];
	LazyOModalLogin: InstanceType<typeof LazyOModalLogin>["$props"];
	LazyOModalSignup: InstanceType<typeof LazyOModalSignup>["$props"];
	LazyOModalQrcode: InstanceType<typeof LazyOModalQrcode>["$props"];
	LazyOModalReRegistration: InstanceType<typeof LazyOModalReRegistration>["$props"];
	LazyOModalReferralInfo: InstanceType<typeof LazyOModalReferralInfo>["$props"];
	LazyOModalSuccessReferral: InstanceType<typeof LazyOModalSuccessReferral>["$props"];
	LazyOModalTournamentsLeaderboard: InstanceType<typeof LazyOModalTournamentsLeaderboard>["$props"];
	LazyOModalAccountChangePassword: InstanceType<typeof LazyOModalAccountChangePassword>["$props"];
	LazyOModalAccountEditContact: InstanceType<typeof LazyOModalAccountEditContact>["$props"];
	LazyOModalCodeConfirm: InstanceType<typeof LazyOModalCodeConfirm>["$props"];
	LazyOModalRegistrationComplete: InstanceType<typeof LazyOModalRegistrationComplete>["$props"];
	LazyOModalTermsDialog: InstanceType<typeof LazyOModalTermsDialog>["$props"];
	LazyOModalWelcome: InstanceType<typeof LazyOModalWelcome>["$props"];
	LazyOModalGame: InstanceType<typeof LazyOModalGame>["$props"];
	LazyOModalInsufficientFunds: InstanceType<typeof LazyOModalInsufficientFunds>["$props"];
	LazyOModalInsufficientWithdrawal: InstanceType<typeof LazyOModalInsufficientWithdrawal>["$props"];
	LazyOModalDemoMode: InstanceType<typeof LazyOModalDemoMode>["$props"];
	LazyOModalPhoneConfirmation: InstanceType<typeof LazyOModalPhoneConfirmation>["$props"];
	LazyOModalPhoneConfirmationInfo: InstanceType<typeof LazyOModalPhoneConfirmationInfo>["$props"];
	LazyOModalPresetPackage: InstanceType<typeof LazyOModalPresetPackage>["$props"];
	LazyOModalEmailConfirm: InstanceType<typeof LazyOModalEmailConfirm>["$props"];
	LazyOModalDailyWheel: InstanceType<typeof LazyOModalDailyWheel>["$props"];
	LazyOModalDailyWheelRules: InstanceType<typeof LazyOModalDailyWheelRules>["$props"];
	LazyOModalWheelOfTreasureHowItWorks: InstanceType<typeof LazyOModalWheelOfTreasureHowItWorks>["$props"];
	LazyOModalWheelOfTreasurePlayWheel: InstanceType<typeof LazyOModalWheelOfTreasurePlayWheel>["$props"];
	LazyOModalWheelOfTreasureWelcome: InstanceType<typeof LazyOModalWheelOfTreasureWelcome>["$props"];
	LazyOModalSwitchToSweeps: InstanceType<typeof LazyOModalSwitchToSweeps>["$props"];
	LazyOModalSeasonWelcome: InstanceType<typeof LazyOModalSeasonWelcome>["$props"];
	LazyOModalSeasonTower: InstanceType<typeof LazyOModalSeasonTower>["$props"];
	LazyOModalPromoInviteFriends: InstanceType<typeof LazyOModalPromoInviteFriends>["$props"];
	LazyOModalSeasonCongratulations: InstanceType<typeof LazyOModalSeasonCongratulations>["$props"];
	LazyOModalScratchCardsWelcome: InstanceType<typeof LazyOModalScratchCardsWelcome>["$props"];
	LazyOModalScratchCardsInfo: InstanceType<typeof LazyOModalScratchCardsInfo>["$props"];
	LazyOModalScratchCardsTicketPlay: InstanceType<typeof LazyOModalScratchCardsTicketPlay>["$props"];
	LazyOModalScratchCardsYouWon: InstanceType<typeof LazyOModalScratchCardsYouWon>["$props"];
	LazyOModalMagicBoxesHowItWorks: InstanceType<typeof LazyOModalMagicBoxesHowItWorks>["$props"];
	LazyOModalMagicBoxesWelcome: InstanceType<typeof LazyOModalMagicBoxesWelcome>["$props"];
	LazyOModalMagicBoxesSelection: InstanceType<typeof LazyOModalMagicBoxesSelection>["$props"];
	LazyOModalWithoutDepositBonus: InstanceType<typeof LazyOModalWithoutDepositBonus>["$props"];
	LazyOModalTournamentsRules: InstanceType<typeof LazyOModalTournamentsRules>["$props"];
	LazyOModalTournamentsPrizes: InstanceType<typeof LazyOModalTournamentsPrizes>["$props"];
	LazyOModalPrizeDropsWin: InstanceType<typeof LazyOModalPrizeDropsWin>["$props"];
	LazyOModalSeasonCards: InstanceType<typeof LazyOModalSeasonCards>["$props"];
	LazyOModalVipClubClaimReward: InstanceType<typeof LazyOModalVipClubClaimReward>["$props"];
	LazyOModalIndependenceSeasonPrize: InstanceType<typeof LazyOModalIndependenceSeasonPrize>["$props"];
	LazyOModalVipClubInfo: InstanceType<typeof LazyOModalVipClubInfo>["$props"];
	LazyOModalVipClubProfile: InstanceType<typeof LazyOModalVipClubProfile>["$props"];
	LazyOModalVipClubLevelsInfo: InstanceType<typeof LazyOModalVipClubLevelsInfo>["$props"];
	LazyOModalGameVipOverview: InstanceType<typeof LazyOModalGameVipOverview>["$props"];
	LazyOModalVipClubStatusUp: InstanceType<typeof LazyOModalVipClubStatusUp>["$props"];
	LazyOModalVipClubSaveProgress: InstanceType<typeof LazyOModalVipClubSaveProgress>["$props"];
	LazyOModalVipClubFinish: InstanceType<typeof LazyOModalVipClubFinish>["$props"];
	LazyOModalBingoHowItWorks: InstanceType<typeof LazyOModalBingoHowItWorks>["$props"];
	LazyOModalBingoWelcome: InstanceType<typeof LazyOModalBingoWelcome>["$props"];
	LazyOModalBingoPlay: InstanceType<typeof LazyOModalBingoPlay>["$props"];
};

const useAppModals = () => useModals<Modals>();

export default useAppModals;
