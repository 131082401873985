import { apiClient, v1, type ExtractFromAPI } from "@netgame/openapi";
import { defu } from "defu";

import useAsync from "./useAsync";
import useIsGuest from "./useIsGuest";

type NextTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/next-tournaments/", "get">;
type PrevTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/tournaments/history/", "get">;

type TournamentsData = {
	"/rest/next-tournaments/": Record<string, NextTournamentsResponse>;
	"/rest/tournaments/history/": Record<string, PrevTournamentsResponse>;
};

const DATA_KEY = "tournaments";
const OFFSET_VALUE = 0;
const LIMIT_VALUE = 4;

const paths = {
	next: "/rest/next-tournaments/",
	prev: "/rest/tournaments/history/"
} as Record<string, keyof TournamentsData>;

const useGetTournamentsData = ({
	immediate = true,
	offsetValue = OFFSET_VALUE,
	limitValue = LIMIT_VALUE
}: {
	immediate?: boolean;
	offsetValue?: number;
	limitValue?: number;
} = {}) => {
	const isGuest = useIsGuest();

	const offset = ref(offsetValue);
	const limit = ref(limitValue);
	const activePath = ref<keyof TournamentsData>("/rest/next-tournaments/");
	const activeTab = ref<"next" | "prev">("next");

	const headers = useRequestHeaders();

	const { execute, ...rest } = useAsync<TournamentsData>(
		DATA_KEY,
		async (nuxtApp) => {
			const prevData = nuxtApp!.payload?.data?.[DATA_KEY];
			try {
				const recordId = `${limit.value}_${offset.value}`;
				if (prevData?.[activePath.value] && recordId in prevData?.[activePath.value]) {
					return prevData;
				}
				const result = await apiClient({
					path: activePath.value,
					method: "get",
					options: {
						query: { limit: limit.value, offset: offset.value },
						headers
					}
				});
				return defu(prevData, {
					[activePath.value]: {
						[recordId]: result
					}
				});
			} catch (err) {
				console.error(err);
				return prevData;
			}
		},
		{
			immediate: immediate && !isGuest.value,
			watch: [isGuest, offset, activePath]
		}
	);

	const invalidateData = async () => {
		clearNuxtData(DATA_KEY);
		await execute();
	};

	const clearData = () => clearNuxtData(DATA_KEY);

	return {
		...rest,
		activeTab,
		offset,
		limit,
		paths,
		activePath,
		execute,
		clearData,
		invalidateData
	};
};

export default useGetTournamentsData;
