import type { H3Event } from "h3";
import { getQuery, setHeader, sendRedirect } from "h3";
import type { FetchError } from "ofetch";
import { splitCookiesString } from "set-cookie-parser";

export default defineNuxtRouteMiddleware(async () => {
	const isGuest = useIsGuest();
	if (isClient && process.client) {
		return;
	}
	try {
		const event = useRequestEvent() as unknown as H3Event;
		const query = getQuery(event);
		const { apiUrl } = useRuntimeConfig();
		const { headers = {} } = event.node.req;
		const res = await $fetch.raw<{ authorized?: boolean; redirectUrl?: string }>("/rest/request/handle/", {
			baseURL: apiUrl as string,
			query,
			headers: pickBy(
				{
					...omit(headers, ["host", "content-length", "connection"]),
					"client-host": headers.host
				},
				(_, value) => !!value
			) as HeadersInit
		});
		isGuest.value = !res._data?.authorized;
		const setCookies = res.headers.get("set-cookie");
		if (setCookies) {
			setHeader(event, "set-cookie", splitCookiesString(setCookies));
		}
		if (!event.node.req.url) {
			return;
		}
		const url = new URL(event.node.req.url, `http://${event.node.req.headers.host}`);
		if ("aa" in query) {
			const redirectQuery = omit(query as Record<string, string>, ["aa"]);
			const newQuery = Object.keys(redirectQuery).length ? `?${new URLSearchParams(redirectQuery)}` : "";
			sendRedirect(event, `${url.pathname}${newQuery}`);
			return;
		}
		if (res._data?.redirectUrl) {
			sendRedirect(event, res._data?.redirectUrl);
		}
	} catch (err) {
		const error = err as FetchError;
		console.error({
			...error,
			status: error.statusCode || 500,
			statusText: error.statusText || "Internal Server Error",
			statusMessage: error.statusMessage || "Internal Server Error",
			request: error.request,
			response: error.response,
			data: error.data
		});
	}
});
