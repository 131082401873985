import { toast } from "vue3-toastify";

import OPrizeDropsTimeToast from "@/organizms/O/PrizeDrops/TimeToast.vue";

const usePrizeDropToast = () => {
	const { t } = useT();
	const toastId = ref();

	if (toast.isActive(toastId.value)) {
		toast.remove(toastId.value);
	}

	const showToastDrop = () => {
		const toastProps = {
			title: t("prizeDrops.toastWithTimer.title"),
			text: t("prizeDrops.toastWithTimer.text"),
			btnText: t("prizeDrops.toastWithTimer.btn"),
			timerText: t("prizeDrops.toast.timeLeft")
		};

		toastId.value = toast.success(h(OPrizeDropsTimeToast, toastProps), {
			containerId: "prize-receive-timer",
			icon: h("img", {
				src: "/nuxt-img/prize-drops/toast-icon.png",
				width: 40,
				height: 40
			}),
			theme: toast.THEME.DARK,
			position: toast.POSITION.TOP_RIGHT,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000,
			onClick: () => {
				navigateTo("/issues/popular-games/");
			}
		});
	};

	return { showToastDrop };
};

export default usePrizeDropToast;
