<script setup lang="ts">
const { t } = useT();
const { magicBoxesIsActive } = useFeaturesIsActive();
const { activeStatus } = useTournamentData({ options: { cached: false, immediate: false } });

defineProps<{ isShowSeason?: boolean }>();
defineEmits<{ (event: "toggleSidebar"): void }>();

const handleOpenCash = () => {
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<div class="mob-navigation">
		<div class="navigation-item" @click="$emit('toggleSidebar')">
			<ASvg name="navigation/menu" class="icon" />
			<AText variant="ternopil" :modifiers="['uppercase', 'semibold']" class="text">{{ t("Menu") }}</AText>
		</div>
		<NuxtLink to="/issues/all-games/" class="navigation-item" active-class="active">
			<ASvg name="navigation/games" class="icon" />
			<AText variant="ternopil" :modifiers="['uppercase', 'semibold']" class="text">{{ t("Games") }}</AText>
		</NuxtLink>

		<div class="navigation-item centered">
			<MMagicBoxesSkinPumpkinProgress v-if="magicBoxesIsActive" />
			<MIndependenceSeasonMiniProgress v-else-if="isShowSeason" class="season" />
			<div v-else class="play-box" @click="handleOpenCash">
				<AText variant="topeka" :modifiers="['uppercase', 'semibold']" class="text play">{{ t("Buy") }}</AText>
			</div>
		</div>
		<LazyOHeaderRewards location-tid="desk" />
		<NuxtLink to="/tournaments/" class="navigation-item" active-class="active">
			<ASvg name="navigation/tournaments" class="icon" />
			<AText variant="ternopil" :modifiers="['uppercase', 'semibold', 'nowrap']" class="text">
				{{ t("Be first!") }}
			</AText>
			<ABadge v-if="activeStatus" :size="16" background="var(--tertiary-400)" class="badge">
				<AText variant="ternopil" :modifiers="['semibold', 'condensed']" as="div"> 1 </AText>
			</ABadge>
		</NuxtLink>
	</div>
</template>

<style scoped lang="scss">
.mob-navigation {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px 20px;
	border-top: 1px solid var(--gray-800);
	border-radius: 12px 12px 0 0;
	background: var(--gray-900);
	box-shadow: 0 -6px 12px 0 rgba(var(--gray-950-rgb), 0.24);
	z-index: 3;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			z-index: 2 !important;
		}
	}
}
.navigation-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;
	width: 40px;
	height: 40px;
	position: relative;
	color: var(--neutral-50);
	cursor: pointer;
	.icon {
		font-size: 24px;
		color: var(--neutral-400);
	}

	&.active {
		color: var(--primary-400);

		.icon {
			color: var(--primary-400);
		}
	}
	.badge {
		position: absolute;
		top: -1px;
		right: -5px;
	}
	&.centered {
		top: -20px;
	}
	.play-box {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 999px;
		color: var(--neutral-950);
		background: var(--primary-400);
		box-shadow: 0 0 10px 0 var(--primary-400);
		width: 56px;
		min-height: 56px;
	}
	.season {
		min-height: 56px;
		position: relative;
	}
}
</style>
