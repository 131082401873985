import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin(() => {
	const router = useRouter();

	router.beforeEach((to, from, next) => {
		const path = to.path;

		if (path.includes("/cash/") && !path.includes("#/cash/")) {
			next(path.replace("/cash/", "/#/cash/"));
		} else {
			next();
		}
	});
});
