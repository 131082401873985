const useHomePage = () => {
	const { select } = useGamesState();
	const { data: appInitData } = useAppInitData();

	const {
		data: pageData,
		refresh,
		jackpotGames,
		gamesWithWeekGame,
		hotGames
	} = useHomeData({ cached: false, server: true });
	const { data: allGamesResponse } = useGetGameFiltersData();

	const isValidBannerPresetPackage = computed(
		() =>
			appInitData.value?.bannerPresetPackage?.promoOfferPreset?.imageMobile2x &&
			appInitData.value?.bannerPresetPackage?.promoOfferPreset?.image2x &&
			appInitData.value?.bannerPresetPackage?.promoOfferPreset?.imageBanner &&
			appInitData.value?.bannerPresetPackage?.promoOfferPreset?.imageBannerMobile
	);

	const banners = computed(() => {
		if (!appInitData.value?.isGuest && appInitData.value?.bannerPresetPackage && isValidBannerPresetPackage.value) {
			return [
				{
					type: "bannerPresetPackage",
					...appInitData.value.bannerPresetPackage
				},
				...(pageData.value?.banners || [])
			];
		}
		return pageData.value?.banners || [];
	});

	const isShowAdventureBanner = computed(() => appInitData.value?.adventureWheel?.isEnabledBannerForGuest);

	const favoriteGames = computed(() => select(appInitData.value?.favoriteGames || []));
	const lastGames = computed(() => select(appInitData.value?.lastGames || []));

	const seoData = computed(() => pageData.value?.seo);
	const jackpots = computed(() => pageData.value?.jackpots);
	const promotion = computed(() => pageData.value?.promotions);
	const gameOfWeek = computed(() => select(pageData?.value?.gameOfWeek || [])[0]);

	return {
		promotion,
		jackpots,
		seoData,
		banners,
		refresh,
		pageData,
		jackpotGames,
		hotGames,
		gamesWithWeekGame,
		favoriteGames,
		lastGames,
		isShowAdventureBanner,
		allGamesResponse,
		gameOfWeek
	};
};

export default useHomePage;
