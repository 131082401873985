<script lang="ts" setup>
const { modals, close, open } = useAppModals();
const { data: appInit } = await useAppInitData();
const route = useRoute();

const { openModal, errorMessage, invited_by: invitedBy, isCompleteRegistration } = route.query;
const { isMobile } = useDevice();
useCookiesSetPromoCode();

const dictionary = {
	login: "LazyOModalLogin",
	register: "LazyOModalSignup",
	register1: "LazyOModalSignup"
} as const;
useOtpGetFlow();

onMounted(() => {
	if (isCompleteRegistration) {
		open("LazyOModalAccountChangePassword");
	}

	if (appInit.value?.isGuest === false) {
		return;
	}
	if (route.path.includes("register")) {
		open("LazyOModalSignup");
		return;
	}
	if (invitedBy) {
		open("LazyOModalSignup");
		dispatchGAEvent({
			event: "promocode",
			location: "deeplink",
			step: "success"
		});
	}

	const modal = dictionary[openModal as keyof typeof dictionary];

	if (typeof openModal !== "string" || !modal) {
		return;
	}

	const error = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage;
	const errorString = typeof error === "string" ? error : undefined;

	open(modal, { error: errorString });
});
</script>

<template>
	<div>
		<Teleport to="body">
			<TransitionGroup :name="modals.prevSize === 0 || modals.nextSize === 0 ? 'modal' : 'multiple'" appear>
				<AOverlay
					v-for="[ModalComponent, properties] in modals.items.entries()"
					:id="ModalComponent.replace('LazyO', '')"
					:key="ModalComponent"
					:modifiers="[isMobile ? 'flex-start' : 'flex-center', 'fixed', 'auto']"
					bg-color="var(--changshu)"
				>
					<component :is="ModalComponent" v-bind="{ onClose: () => close(ModalComponent), ...properties }" />
				</AOverlay>
			</TransitionGroup>
		</Teleport>
	</div>
</template>

<style lang="scss" scoped>
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9998;
	--a-overlay-cursor: default;

	&#ModalScratchCardsYouWon {
		background: transparent;
	}

	&#ModalInsufficientFunds,
	&#ModalDemoMode {
		@include media-breakpoint-down(lg) {
			@media screen and (orientation: landscape) {
				justify-content: flex-start;
			}
		}
	}
}
.modal-enter-active,
.modal-leave-active {
	transition: opacity 350ms ease-in-out;
}

.modal-enter-from,
.modal-leave-to {
	opacity: 0;
}
</style>
