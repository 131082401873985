<script lang="ts" setup>
const props = defineProps<{
	rank: string;
	width: number;
	height: number;
	iconSize: number;
	isVip?: boolean;
}>();

const originWidth = computed(() => `${props.width / 2}px`);
const originHeight = computed(() => `${props.height / 2}px`);
const textGradient = computed(() =>
	props.isVip
		? "linear-gradient(180deg, #F9F102 26.79%, #FF6B00 71.73%)"
		: "linear-gradient(180deg, #b03801 0%, #501a01 100%)"
);
</script>

<template>
	<div class="status-icon">
		<NuxtImg class="img" :src="`/nuxt-img/vip/level-icon${isVip ? '-vip' : ''}.png`" :width="width" :height="height" />
		<AText :class="['number', { vip: isVip }]" :gradient="textGradient">
			<span>{{ rank }}</span>
		</AText>
	</div>
</template>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Titan+One&display=swap");

.status-icon {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.img,
	.icon {
		transition: all 0.5s ease;
	}

	.img {
		width: v-bind(originWidth);
		height: v-bind(originHeight);
		opacity: 1;
		transform: scale(1);

		&.vip {
			transform: scale(1.2);
			opacity: 1;
		}
	}

	.number {
		position: absolute;
		top: 42%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		margin-top: 7%;
		font-size: calc(v-bind(originWidth) / 2.8);
		font-family: "Titan One", sans-serif;
		font-weight: 400;
		filter: drop-shadow(0 1px 0 #fff9c7);
		span {
			display: block;
			line-height: 1.15;
			font-weight: 400;
		}
		&.vip {
			margin-top: 0;
			font-size: calc(v-bind(originWidth) / 3.7);
		}
	}
}
</style>
